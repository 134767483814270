import * as async from 'async';
import * as d3 from 'd3';
import * as dateFns from 'date-fns';
import * as supabase from '@supabase/supabase-js';
import Mention from 'quill-mention';
import Quill from 'quill';
import _ from 'lodash';
import cookies from 'js-cookie';
// import { CubejsApi as cubejs } from '@cubejs-client/core';
import cubejs from '@cubejs-client/core';
import exceljs from 'exceljs';
import jstat from 'jstat';
import libAxios from 'axios';
import libMapGL from 'mapbox-gl';
import modelData from '@colt-builders-corp/estimating-tool-ml';
import { createContainer, Lifetime, asValue, InjectionMode, asClass, asFunction } from 'awilix';
import { tip as d3tip } from 'd3-v6-tip';
import { to } from 'await-to-js';
import numeral from 'numeral';
import uuidPkg from 'uuid';
import * as validators from './lib/validators/validate-esm.mjs';
import ajv from './lib/validators/index.mjs';
import ActivityRepo from './repository/ActivityRepo.mjs';
import ArchitectRepo from './repository/ArchitectRepo.mjs';
import ArchivalReasonRepo from './repository/BidArchivalReasonRepo.mjs';
import AuthService from './ui/services/auth-service.mjs';
import BidCSTakeOffRepo from './repository/BidCSTakeOffRepo.mjs';
import BidRepo from './repository/BidRepo.mjs';
import BuildingRepo from './repository/BuildingRepo.mjs';
import CSStatusRepo from './repository/CSStatusRepo.mjs';
import CSSheetRepo from './repository/CSSheetRepo.mjs';
import CSSubVsMHRepo from './repository/CSSubVsMHRepo.mjs';
import CommentRepo from './repository/CommentRepo.mjs';
import CostConverterService from './service/CostConverterService.mjs';
import EmailRepo from './repository/EmailRepo.mjs';
import EstimatorRepo from './repository/EstimatorRepo.mjs';
import FeedbackRepo from './repository/FeedbackRepo.mjs';
import FileRepo from './repository/FileRepo.mjs';
import FredRepo from './repository/FREDRepo.mjs';
import GeneralContractorRepo from './repository/GeneralContractorRepo.mjs';
import HashService from './ui/services/hash-service.mjs';
import HistoricalProjectRepo from './repository/HistoricalProjectRepo.mjs';
import LaborPredictionRepo from './repository/LaborPredictionRepo.mjs';
import LevelRepo from './repository/LevelRepo.mjs';
import LogDnaFactory from './factory/LogDnaFactory.mjs';
import MaterialPredictionRepo from './repository/MaterialPredictionRepo.mjs';
import MaterialPricingRepo from './repository/MaterialPricingRepo.mjs';
import MocksService from './service/MocksService.mjs';
import PricingPointRepo from './repository/PricingPointRepo.mjs';
import ProjectManagerRepo from './repository/ProjectManagerRepo.mjs';
import ProjectRepo from './repository/ProjectRepo.mjs';
import RGMRepo from './repository/RGMRepo.mjs';
import RandomLengthPricingRepo from './repository/RandomLengthPricingRepo.mjs';
import RegionRepo from './repository/RegionRepo.mjs';
import RegionRevisionRepo from './repository/RegionRevisionRepo.mjs';
import TrainingDataAssociationLogRepo from './repository/TrainingDataAssociationLogRepo.mjs';
import SiteSupervisorRepo from './repository/SiteSupervisorRepo.mjs';
import StructuralEngineerRepo from './repository/StructuralEngineerRepo.mjs';
import SupabaseClientFactory from './factory/SupabaseUIClientFactory.mjs';
import SupabaseQueryService from './service/SupabaseQueryService.mjs';
import SupplierAttachmentRepo from './repository/SupplierAttachmentRepo.mjs';
import SupplierRepo from './repository/SupplierRepo.mjs';
import TableFilterRepo from './repository/TableFilterRepo.mjs';
import TakeOffRowRepo from './repository/TakeOffRowRepo.mjs';
import UserRepo from './repository/UserRepo.mjs';
import ValidateService from './service/ValidateService.mjs';
import WhiteboardSheetRepo from './repository/sheet/WhiteboardSheetRepo.mjs';
import PursuitRepo from './repository/sheet/PursuitRepo.mjs';
// import logdna from '@logdna/browser';

import predictionRepo from './repository/PredictionRepo.mjs';
import sbTo from './lib/SupabaseToCorrect.mjs';
import supabaseTo from './lib/SupabaseTo.mjs';
import t from './types.mjs';
import { prediction } from '../config/default.mjs';

// @TODO - add this to the config?
//  - This fails with the new loader
//  in ui:test - this is what it is
/*        {
        CubejsApi: [Function: CubejsApi],
        DEFAULT_GRANULARITY: 'day',
        GRANULARITIES: [
          { name: undefined, title: 'w/o grouping' },
          { name: 'second', title: 'Second' },
          { name: 'minute', title: 'Minute' },
          { name: 'hour', title: 'Hour' },
          { name: 'day', title: 'Day' },
          { name: 'week', title: 'Week' },
          { name: 'month', title: 'Month' },
          { name: 'quarter', title: 'Quarter' },
          { name: 'year', title: 'Year' }
        ],
        HttpTransport: [Function: HttpTransport],
        ResultSet: [Function: ResultSet],
        aliasSeries: [Function: aliasSeries],
        areQueriesEqual: [Function: areQueriesEqual],
        default: [Function: index],
        defaultHeuristics: [Function: defaultHeuristics],
        defaultOrder: [Function: defaultOrder],
        flattenFilters: [Function: flattenFilters],
        getOrderMembersFromOrder: [Function: getOrderMembersFromOrder],
        getQueryMembers: [Function: getQueryMembers],
        isQueryPresent: [Function: isQueryPresent],
        moveItemInArray: [Function: moveItemInArray],
        movePivotItem: [Function: movePivotItem],
        removeEmptyQueryFields: [Function: removeEmptyQueryFields],
        validateQuery: [Function: validateQuery]
      }
*/
let cube = cubejs;
if (cubejs.default) {
	cube = cubejs.default;
}
const cubejsApi = cube(
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NjEwMTY2MjV9.Ihygl3PlY99a4Kx4NDhGBUgv_EDYNjtTjFwx8Aj8swg',
	{
		apiUrl: 'https://bronze-chicken.gcp-us-central1.cubecloudapp.dev/cubejs-api/v1',
	},
);

const { Workbook } = exceljs;

Quill.register({
	'modules/mention': Mention,
});

const { enums: {
	regions,
	bid_types: bidTypes,
	project_types: projectTypes,
	project_status_types: projectStatus,
	bid_status_types: bidStatus,
	building_types: buildingTypes,
	wall_height_types: wallHeights,
	material_types: materialsCategories,
	bid_archival_general_reason_types: bidArchivalReasonTypes,
	plan_types: planTypes,
	takeoff_types: takeoffTypes,
	filter_types: filterTypes,
	data_types: dataTypes,
	bid_award_status_types: bidAwardStatus,
	bid_grades: bidGrades,
	scope_types: bidScopeTypes,
} } = t;

const types = {
	bidTypes,
	regions,
	projectTypes,
	projectStatus,
	bidStatus,
	buildingTypes,
	wallHeights,
	materialsCategories,
	bidArchivalReasonTypes,
	planTypes,
	takeoffTypes,
	filterTypes,
	dataTypes,
	bidAwardStatus,
	bidGrades,
	bidScopeTypes,
};

const { v4: uuidv4 } = uuidPkg;

const intializeContainer = async () => {
	const diContainer = createContainer({
		injectionMode: InjectionMode.PROXY,
	});

	diContainer.register({
		_: asValue(_, { lifetime: Lifetime.SINGLETON }),
		__v_isRef: asValue(false),
		activityRepo: asClass(ActivityRepo, { lifetime: Lifetime.SINGLETON }),
		architectRepo: asClass(ArchitectRepo, { lifetime: Lifetime.SINGLETON }),
		archivalReasonRepo: asClass(ArchivalReasonRepo, { lifetime: Lifetime.SINGLETON }),
		authService: asClass(AuthService, { lifetime: Lifetime.SINGLETON }),
		bidCSTakeOffRepo: asClass(BidCSTakeOffRepo, { lifetime: Lifetime.SINGLETON }),
		bidRepo: asClass(BidRepo, { lifetime: Lifetime.SINGLETON }),
		boxFactory: asValue({}, Lifetime.SINGLETON),
		buildingRepo: asClass(BuildingRepo, { lifetime: Lifetime.SINGLETON }),
		commentRepo: asClass(CommentRepo, { lifetime: Lifetime.SINGLETON }),
		cookies: asValue(cookies, { lifetime: Lifetime.TRANSIENT }),
		costConverterService: asClass(CostConverterService, { lifetime: Lifetime.SINGLETON }),
		csStatusRepo: asClass(CSStatusRepo, { lifetime: Lifetime.SINGLETON }),
		csSheetRepo: asClass(CSSheetRepo, { lifetime: Lifetime.SINGLETON }),
		csSubVsMHRepo: asClass(CSSubVsMHRepo, { lifetime: Lifetime.SINGLETON }),
		emailRepo: asClass(EmailRepo, { lifetime: Lifetime.TRANSIENT }),
		engineerRepo: asClass(StructuralEngineerRepo, { lifetime: Lifetime.SINGLETON }),
		enums: asValue(t.enums, { lifetime: Lifetime.SINGLETON }),
		estimatorRepo: asClass(EstimatorRepo, { lifetime: Lifetime.SINGLETON }),
		feedbackRepo: asClass(FeedbackRepo, { lifetime: Lifetime.SINGLETON }),
		fileRepo: asClass(FileRepo, { lifetime: Lifetime.SINGLETON }),
		fredRepo: asClass(FredRepo, { lifetime: Lifetime.SINGLETON }),
		generalContractorRepo: asClass(GeneralContractorRepo, { lifetime: Lifetime.SINGLETON }),
		hashService: asClass(HashService, { lifetime: Lifetime.SINGLETON }),
		historicalProjectRepo: asClass(HistoricalProjectRepo, { lifetime: Lifetime.SINGLETON }),
		laborPredictionRepo: asClass(LaborPredictionRepo, { lifetime: Lifetime.SINGLETON }),
		tableFilterRepo: asClass(TableFilterRepo, { lifetime: Lifetime.SINGLETON }),
		libSupabase: asValue(supabase, Lifetime.SINGLETON),
		typesFile: asValue(t, { lifetime: Lifetime.SINGLETON }),
		types: asValue(types, { lifetime: Lifetime.SINGLETON }),
		levelRepo: asClass(LevelRepo, { lifetime: Lifetime.SINGLETON }),
		libAjv: asValue(ajv, { lifetime: Lifetime.SINGLETON }),
		libAsync: asValue(async, Lifetime.SINGLETON),
		libAsFunction: asValue(asFunction, Lifetime.TRANSIENT),
		libAsValue: asValue(asValue, Lifetime.TRANSIENT),
		libAsClass: asValue(asClass, Lifetime.TRANSIENT),
		libAxios: asValue(libAxios, Lifetime.SINGLETON),
		libBox: asValue({ client: () => {} }, Lifetime.SINGLETON),
		libCubeApi: asValue(cubejsApi, Lifetime.SINGLETON),
		libD3: asValue(d3, { lifetime: Lifetime.SINGLETON }),
		libD3Tip: asValue(d3tip, { lifetime: Lifetime.SINGLETON }),
		libDateFns: asValue(dateFns, Lifetime.SINGLETON),
		libExcelWB: asClass(Workbook, { lifetime: Lifetime.TRANSIENT }),
		libJstat: asValue(jstat, { lifetime: Lifetime.SINGLETON }),
		//		libLogdna: asValue(logdna, { lifetime: Lifetime.TRANSIENT }),
		libMapGL: asValue(libMapGL, Lifetime.SINGLETON),
		libNumeral: asValue(numeral, Lifetime.SINGLETON),
		libQuill: asValue(Quill, { lifetime: Lifetime.TRANSIENT }),
		libValidators: asValue(validators, Lifetime.SINGLETON),
		logDnaFactory: asFunction(LogDnaFactory, { lifetime: Lifetime.SINGLETON }),
		materialPredictionRepo: asClass(MaterialPredictionRepo, { lifetime: Lifetime.SINGLETON }),
		materialPricingRepo: asClass(MaterialPricingRepo, { lifetime: Lifetime.SINGLETON }),
		mocksService: asClass(MocksService, { lifetime: Lifetime.SINGLETON }),
		modelData: asValue(modelData, { lifetime: Lifetime.SINGLETON }),
		predictionRepo: asClass(predictionRepo, { lifetime: Lifetime.SINGLETON }),
		predictionVersions: asValue(prediction, { lifetime: Lifetime.SINGLETON }),
		pricingPointRepo: asClass(PricingPointRepo, { lifetime: Lifetime.SINGLETON }),
		projectManagerRepo: asClass(ProjectManagerRepo, { lifetime: Lifetime.SINGLETON }),
		projectRepo: asClass(ProjectRepo, { lifetime: Lifetime.SINGLETON }),
		randomLengthPricingRepo: asClass(RandomLengthPricingRepo, { lifetime: Lifetime.SINGLETON }),
		regionRepo: asClass(RegionRepo, { lifetime: Lifetime.SINGLETON }),
		regionRevisionRepo: asClass(RegionRevisionRepo, { lifetime: Lifetime.SINGLETON }),
		registry: asValue(diContainer, Lifetime.SINGLETON),
		rgmRepo: asClass(RGMRepo, { lifetime: Lifetime.SINGLETON }),
		sbTo: asValue(sbTo, { lifetime: Lifetime.TRANSIENT }),
		siteSupervisorRepo: asClass(SiteSupervisorRepo, { lifetime: Lifetime.SINGLETON }),
		supaTo: asValue(supabaseTo, { lifetime: Lifetime.TRANSIENT }),
		supabaseClientFactory: asFunction(SupabaseClientFactory, { lifetime: Lifetime.SINGLETON }),
		supabaseQueryService: asClass(SupabaseQueryService, { lifetime: Lifetime.SINGLETON }),
		supplierAttachmentRepo: asClass(SupplierAttachmentRepo, { lifetime: Lifetime.SINGLETON }),
		trainingDataAssociationLogRepo: asClass(TrainingDataAssociationLogRepo, { lifetime: Lifetime.SINGLETON }),
		supplierRepo: asClass(SupplierRepo, { lifetime: Lifetime.SINGLETON }),
		takeOffRowRepo: asClass(TakeOffRowRepo, { lifetime: Lifetime.SINGLETON }),
		textProcessingService: asValue({}, Lifetime.SINGLETON),
		to: asValue(to, { lifetime: Lifetime.TRANSIENT }),
		userRepo: asClass(UserRepo, { lifetime: Lifetime.SINGLETON }),
		validateService: asClass(ValidateService, { lifetime: Lifetime.SINGLETON }),
		whiteboardSheetRepo: asClass(WhiteboardSheetRepo, { lifetime: Lifetime.SINGLETON }),
		pursuitRepo: asClass(PursuitRepo, { lifetime: Lifetime.SINGLETON }),
		libUUID: asValue(uuidv4, Lifetime.SINGLETON),
	});
	return diContainer;
};

export default intializeContainer;
