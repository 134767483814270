import Vue from 'vue';
import Vuex from 'vuex';

import activeBid from './modules/active-bid-store.mjs';
import activeProject from './modules/active-project-store.mjs';
import activityLog from './modules/activity-log-store.mjs';
import alert from './modules/alert-store.mjs';
import architect from './modules/architect-store.mjs';
import archivedBidLog from './modules/archived-bid-log-store.mjs';
import archivedProject from './modules/archived-project-store.mjs';
import authentication from './modules/auth-store.mjs';
import bidCSTakeOff from './modules/bidcs-takeoff-store.mjs';
import bidLog from './modules/bid-log-store.mjs';
import comment from './modules/comment-store.mjs';
import csSheet from './modules/cssheet-store.mjs';
import dashboardReport from './modules/dashboard-report-store.mjs';
import engineer from './modules/engineer-store.mjs';
import estimator from './modules/estimator-store.mjs';
import feedback from './modules/feedback-store.mjs';
import file from './modules/file-store.mjs';
import generalContractor from './modules/general-contractor-store.mjs';
import historicalProject from './modules/historical-project-store.mjs';
import inbox from './modules/inbox-store.mjs';
import laborPrediction from './modules/labor-prediction-store.mjs';
import leftNav from './modules/left-nav-store.mjs';
import level from './modules/level-store.mjs';
import materialPrediction from './modules/material-prediction-store.mjs';
import materialPricing from './modules/material-pricing-store.mjs';
import modal from './modules/modal-store.mjs';
import project from './modules/project-store.mjs';
import projectManager from './modules/project-manager-store.mjs';
import randomLengths from './modules/random-lengths-store.mjs';
import region from './modules/region-store.mjs';
import regionalReport from './modules/regional-report-store.mjs';
import rgm from './modules/rgm-store.mjs';
import rightNavbarStore from './modules/right-navbar-store.mjs';
import sheet from './modules/sheet-store.mjs';
import siteSupervisor from './modules/site-supervisor-store.mjs';
import subVsMH from './modules/sub-vs-mh-store.mjs';
import supplier from './modules/supplier-store.mjs';
import supplierAttachment from './modules/supplier-attachment-store.mjs';
import tableSettings from './modules/table-settings-store.mjs';
import takeOffRow from './modules/takeOffRow-store.mjs';
import types from './modules/types-store.mjs';
import user from './modules/user-store.mjs';
import trainingData from './modules/training-data-store.mjs';
import validation from './modules/validation-store.mjs';
import filtersNav from './modules/filters-nav-store.mjs';
import pursuit from './modules/pursuit-store.mjs';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		activeBid,
		activeProject,
		activityLog,
		alert,
		architect,
		archivedBidLog,
		archivedProject,
		authentication,
		bidLog,
		comment,
		dashboardReport,
		engineer,
		estimator,
		feedback,
		file,
		generalContractor,
		historicalProject,
		inbox,
		laborPrediction,
		leftNav,
		level,
		materialPrediction,
		materialPricing,
		modal,
		project,
		projectManager,
		randomLengths,
		region,
		regionalReport,
		rgm,
		filtersNav,
		bidCSTakeOff,
		csSheet,
		subVsMH,
		rightNavbarStore,
		sheet,
		siteSupervisor,
		supplier,
		supplierAttachment,
		tableSettings,
		takeOffRow,
		trainingData,
		types,
		user,
		validation,
		pursuit,
	},
});
