export default class BidRepo {
	constructor({ libAxios, to, supaTo, supabaseClientFactory, _, supabaseQueryService, sbTo }) {
		this.libAxios = libAxios;
		this.to = to;
		this.supaTo = supaTo;
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
		this.supabaseQueryService = supabaseQueryService;
		this.selectRevision = `
			id,
			bid_id,
			bid_type,
			bid_done,
			award_date,
			completed,
			award_status,
			grade,
			bid_scope,
			estimated_start,
			estimated_finish,
			itb_received,
			rfq_received,
			estimated_hours,
			actual_hours,
			revision_num,
			job_no,
			target_win,
			description,
			project_notes,
			estimator (
				id,
				name
			),
			non_standard,
			estimator_id,
			takeoff_url,
			takeoff_type,
			project_type,
			general_contractor (
				id,
				name
			),
			bid_supplier (
				bid_revision_id,
				category,
				rfq_sent_at,
				rfq_response_at,
				amount,
				in_quote,
				supplier (
					id,
					name
				)
			),
			region_id,
			structural_engineer_id,
			architect_id,
			frame_start,
			frame_end,
			bid_due,
			box_folder_url,
			fieldwire_url,
			rfq_url,
			address,
			latitude,
			longitude,
			manual_latitude,
			manual_longitude,
			city,
			state,
			is_panel_job,
			square_footage,
			porch_square_footage,
			units,
			plan_type,
			plan_date,
			estimated_labor,
			estimated_material,
			estimated_total,
			modified_at,
			created_at,
			building (
				id,
				type,
				name,
				subtype,
				floors_included,
				building_wrap_included,
				hardware_included,
				windows_doors_included,
				walls_included,
				roof_gross_square_footage,
				roof_included,
				order,
				created_at,
				modified_at,
				level (
					id,
					type,
					number_of_units,
					floor_gross_square_footage,
					porch_gross_square_footage,
					floor_man_hours,
					floor_labor_cost,
					floor_material_cost,
					wall_gross_square_footage,
					wall_man_hours,
					wall_labor_cost,
					wall_material_cost,
					wall_height,
					included,
					created_at,
					modified_at
				)
			)
`;

		this.selectBidWithRelations = ` 
		id,
		name,
		status,
		archived,
		bid_number,
		current_revision_id,
		bid_revision!current_revision_id (${this.selectRevision}
)`;
	}

	async getAllBids(params = { from: 0, to: 20, filters: {}, sort: { desc: false, by: '' } }) {
		const query = this.supabase
			.from('bid')
			.select(this.selectBidWithRelations, { count: 'exact' })
			.eq('deleted', 'false')
			.eq('archived', 'false')
			.range(params.from, params.to);

		const [error, data] = await this.supaTo(query);
		if (error) {
			throw error;
		}
		return data;
	}

	async getBidLog(params) {
		const base = this.supabase
			.from('bid_log')
			.select('*', { count: 'exact' });
		const query = this.supabaseQueryService.createQuery(base, params);
		const [error, data] = await this.supaTo(query);
		if (error) {
			throw error;
		}
		return data;
	}

	async downloadBidLog(to, params, columns, name) {
		const [error, data] = await this.sbTo(this.supabase.functions.invoke('create-xlsx', {
			body: JSON.stringify({
				to,
				name,
				params,
				table: 'bid_log',
				columns,
			}),
		}));

		if (error) {
			throw error;
		}
		return data;
	}

	async downloadArchivedBidLog(to, params, columns, name) {
		const [error, data] = await this.sbTo(this.supabase.functions.invoke('create-xlsx', {
			body: JSON.stringify({
				to,
				name,
				params,
				table: 'archived_bid_log',
				columns,
			}),
		}));

		if (error) {
			throw error;
		}
		return data;
	}

	async getArchivedBidLog(params) {
		const base = this.supabase
			.from('archived_bid_log')
			.select('*', { count: 'exact' });

		const query = this.supabaseQueryService.createQuery(base, params);

		const [error, data] = await this.supaTo(query);
		if (error) {
			throw error;
		}
		return data;
	}

	async getBidByName(name) {
		const [error, data] = await this.sbTo(
			this.supabase
				.from('bid')
				.select(` 
			id,
			name,
			status,
			bid_revision!current_revision_id (
				id,
				bid_id,
				revision_num,
				job_no,
				description,
				project_notes,
				estimator_id,
				non_standard,
				takeoff_url,
				takeoff_type,
				project_type,
				region_id (
					*,
					region_name: region_name_id(
						name,
						full_name
					)
				),
				general_contractor (
					id,
					name
				),
				supplier (
					id,
					name,
					bid_supplier (
						bid_revision_id,
						supplier_id,
						category,
						rfq_sent_at,
						rfq_response_at
					)
				),
				structural_engineer_id,
				architect_id,
				frame_start,
				frame_end,
				bid_due,
				box_folder_url,
				fieldwire_url,
				city,
				state,
				modified_at,
				created_at,
				building (
					id,
					type,
					name,
					subtype,
					floors_included,
					building_wrap_included,
					hardware_included,
					windows_doors_included,
					walls_included,
					roof_gross_square_footage,
					roof_included,
					order,
					created_at,
					modified_at,
					level (
						id,
						type,
						number_of_units,
						floor_gross_square_footage,
						floor_man_hours,
						floor_labor_cost,
						floor_material_cost,
						wall_gross_square_footage,
						wall_man_hours,
						wall_labor_cost,
						wall_material_cost,
						wall_height,
						included,
						created_at,
						modified_at
			)
		)
         )
       `).match({ name }),
		);

		if (error) {
			throw error;
		}
		return data;
	}

	//	Gets bid + most recent revision
	async getBidById(id) {
		if (!id) {
			throw new Error('id is required for get bid by id');
		}

		const [error, data] = await this.sbTo(
			this.supabase
				.from('bid')
				.select(this.selectBidWithRelations)
				.eq('id', id),
		);
		if (error) {
			throw error;
		}

		return this._.get(data, '[0]', null);
	}

	//	Gets bid + and requested revision
	async getBidByIdAndRev(id, rev) {
		if (!id) {
			throw new Error('id is required for get bid by id');
		}
		if (!rev) {
			throw new Error('rev is required for get bid with rev');
		}

		const [err1, bid] = await this.to(this.getBidById(id));
		if (err1) {
			throw err1;
		}

		if (!bid) {
			throw new Error('No bid found');
		}

		const [error, data] = await this.sbTo(
			this.supabase
				.from('bid_revision')
				.select(this.selectRevision)
				.eq('id', rev),
		);
		if (error) {
			throw error;
		}

		const bidRevision = this._.get(data, '[0]', null);
		if (bidRevision == null) {
			throw new Error('No bid revision found');
		}

		bid.bid_revision = bidRevision;

		return bid;
	}

	async getBidRevisionByStateAndSquareFootage(state, squareFootage) {
		if (!state) {
			throw new Error('state is required for getBidByStateAndSquareFootage');
		}

		if (!squareFootage) {
			throw new Error('squareFootage is required for getBidByStateAndSquareFootage');
		}

		const [error, data] = await this.supaTo(
			this.supabase.from('bid_revision').select().match({ state, square_footage: squareFootage }),
		);
		if (error) {
			throw error;
		}
		return data.data;
	}

	//	Gets only bid, not the most recent revision
	async getBidOnlyById(bidId) {
		if (!bidId) {
			throw new Error('bidId is required for getBidOnlyById');
		}

		//  1. Get bid
		const [error, data] = await this.supaTo(
			this.supabase.from('bid').select().eq('id', bidId),
		);
		if (error) {
			throw error;
		}
		return this._.get(data, 'data.length') ? data.data[0] : null;
	}

	async getBidRevisionById(id) {
		if (!id) {
			throw new Error('id is required to get a bid revision');
		}
		const [error, data] = await this.supaTo(
			this.supabase.from('bid_revision').select().eq('id', id),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data[0]', null);
		}
	}

	async getBidsByRegion(region) {
		if (!region) {
			throw new Error('region is required to fetch a bid by region');
		}
		const [error, data] = await this.supaTo(
			this.supabase.rpc('get_bids_by_region', { r: region }),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data.length') ? data.data : [];
		}
	}

	async getArchivalReason(bidId) {
		if (!bidId) {
			throw new Error('bidId is required to get a bid archival reason');
		}
		const [error, data] = await this.supaTo(
			this.supabase.from('bid_archival_reason').select().eq('bid_id', bidId),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data[0]', null);
		}
	}

	async getBidRevisionByBidName(bidName) {
		if (!bidName) {
			throw new Error('bidName is required to fetch a bid revision by bid name');
		}
		const [error, data] = await this.supaTo(
			this.supabase.from('bid').select(`
				id,
				name,
				bid_revision!current_revision_id ( * )
			`).eq('name', bidName),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data.length') ? data.data : [];
		}
	}

	async setRegionIdByRevisionIds(ids, regionId) {
		if (!ids) {
			throw new Error('an array of revision ids is required to set region');
		}

		if (!regionId) {
			throw new Error('revisionId is required to set region');
		}
		const [error] = await this.supaTo(
			this.supabase.from('bid_revision').update({ region_id: regionId }).in('id', ids),
		);
		if (error) {
			throw error;
		}
	}

	async setStatusByBidIds(ids, status) {
		if (!ids) {
			throw new Error('an array of bid ids is required to set bid status');
		}

		if (!status) {
			throw new Error('status is required to set status');
		}
		const [error] = await this.supaTo(
			this.supabase.from('bid').update({ status }).in('id', ids),
		);
		if (error) {
			throw error;
		}
	}

	async getBidCountsPerRegion() {
		const [error, data] = await this.supaTo(
			this.supabase.rpc('get_bid_counts_per_region'),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data.length') ? data.data : [];
		}
	}

	async getBidCountsPerEstimator() {
		const [error, data] = await this.supaTo(
			this.supabase.rpc('get_bid_counts_per_estimator'),
		);
		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data.length') ? data.data : [];
		}
	}

	// Soft delete bid by id;
	async deleteBidById(id) {
		if (!id) {
			throw new Error('id is required to delete a bid');
		}
		const [error, data] = await this.supaTo(
			this.supabase.from('bid').update({ deleted: true }).match({ id }),
		);

		if (error) {
			throw error;
		} else {
			return this._.get(data, 'data[0]', null);
		}
	}

	async updateProjectNotes(bidRevisionId, projectNotes) {
		if (!bidRevisionId) {
			throw new Error('bidRevisionId is required to update project notes');
		}

		if (!projectNotes) {
			throw new Error('projectNotes is required to update project notes');
		}

		const [error] = await this.supaTo(
			this.supabase.from('bid_revision').update({ project_notes: projectNotes }).match({ id: bidRevisionId }),
		);

		if (error) {
			throw error;
		}
	}

	// Archive bid by id;
	async archiveBidById(id) {
		if (!id) {
			throw new Error('id is required to archive a bid');
		}
		const [error, data] = await this.supaTo(
			this.supabase.from('bid').update({ archived: true }).match({ id }),
		);
		if (error) {
			throw error;
		} else {
			const result = this._.get(data, 'data[0]', null);
			return result;
		}
	}

	// create bid
	async createBid() {
		const [error, id] = await this.sbTo(this.supabase.rpc('create_bid_fn'));
		if (error) {
			throw error;
		}

		return this.getBidById(id);
	}

	// update bid
	async updateBid(bid) {
		if (!bid) {
			throw new Error('bid is required to update bid');
		}
		const [error] = await this.supaTo(
			this.supabase.from('bid').update(bid).match({ id: bid.id }),
		);
		if (error) {
			throw error;
		}

		return this.getBidById(bid.id);
	}

	//  create bid revision
	async reviseBid(bidId) {
		if (!bidId) {
			throw new Error('bidId is required to revise bid');
		}
		const [error] = await this.sbTo(
			this.supabase.rpc('revise_bid_fn', { bid_id: bidId }),
		);
		if (error) {
			throw error;
		}

		return this.getBidById(bidId);
	}

	async setHash(revisionId, hash) {
		const bidRevision = await this.getBidRevisionById(revisionId);

		bidRevision.hash = hash;

		return this.updateBidRevision(bidRevision);
	}

	// update bid revision
	async updateBidRevision(revision) {
		if (!revision) {
			throw new Error('bid revision is required to update a bid revision');
		}
		if (revision.building) {
			delete revision.building;
		}

		const [error] = await this.sbTo(
			this.supabase
				.from('bid_revision')
				.update(revision)
				.match({ id: revision.id }),
		);

		if (error) {
			throw error;
		}

		return this.getBidById(revision.bid_id);
	}

	// clone bid
	async cloneBid(bidId) {
		if (!bidId) {
			throw new Error('bidId is required to clone bid');
		}
		const [error, id] = await this.sbTo(
			this.supabase.rpc('clone_bid_fn', { bid_id: bidId }),
		);
		if (error) {
			throw error;
		}

		return this.getBidById(id);
	}

	async generateBidNumber(date) {
		if (!date) {
			throw new Error('date is required to generate bidNum');
		}
		const [error, data] = await this.sbTo(
			this.supabase.rpc('generate_bid_number', { date }),
		);

		if (error) {
			throw error;
		}

		return this._.get(data, '[0]', null);
	}

	async getOnlyBidByName(name) {
		const [error, data] = await this.sbTo(
			this.supabase
				.from('bid')
				.select('name').match({ name }),
		);

		if (error) {
			throw error;
		}
		return data;
	}

	async getSuppliersByBidRevision(bidRevisionId) {
		const [error, data] = await this.sbTo(
			this.supabase
				.from('bid_supplier_view')
				.select('*')
				.match({ bid_revision_id: bidRevisionId }),
		);

		if (error) {
			throw error;
		}
		return data;
	}

	async getRevisionsByBidId(bidId) {
		const [error, data] = await this.sbTo(
			this.supabase
				.from('bid_revision')
				.select('*')
				.match({ bid_id: bidId }),
		);

		if (error) {
			throw error;
		}

		return data;
	}
}
