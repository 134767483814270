export default class BuildingRepo {
	constructor({ to, supaTo, sbTo, supabaseClientFactory, _, libUUID }) {
		this.to = to;
		this.supaTo = supaTo;
		this.sbTo = sbTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
		this.uuid = libUUID;
	}

	async createBuilding(revisionId, name) {
		if (!revisionId) {
			throw new Error('Revision ID is required to create a new building');
		}

		const [err, data] = await this.sbTo(
			this.supabase.rpc('create_building_fn', { current_bid_revision_id: revisionId, building_name: name }),
		);

		if (err) {
			throw err;
		}

		return data[0];
	}

	async createBuildingWithAdditionalProps(revisionId, building) {
		const [err, result] = await this.to(this.createBuilding(revisionId, building.name));

		if (err) {
			throw err;
		}

		const buildingWithProps = Object.assign(result, building);

		const [err1, res] = await this.to(this.updateBuilding(buildingWithProps));

		if (err1) {
			throw err1;
		}

		return res;
	}

	async getBuildingById(buildingId) {
		if (!buildingId) {
			throw new Error('Building ID is required to get building');
		}

		const [error, response] = await this.supaTo(
			this.supabase
				.from('building')
				.select('*')
				.match({ id: buildingId }),
		);

		if (error) {
			throw error;
		} else {
			return response.data[0];
		}
	}

	async updateBuilding(building) {
		if (!building) {
			throw new Error('A building is required to update building');
		}
		const [error, data] = await this.supaTo(
			this.supabase
				.from('building')
				.update(building)
				.match({ id: building.id }),
		);
		if (error) {
			throw error;
		} else if (this._.has(data, 'data.length')) {
			const result = await this.getBuildingById(building.id);
			return result;
		} else {
			throw new Error('Unable to bid building.');
		}
	}

	async upsertBuilding(building) {
		if (!building) {
			throw new Error('building is required to upsert buidling.');
		}

		if (!this._.has(building, 'id') || this._.isNil(building.id)) {
			building.id = this.uuid();
		}
		const [err, response] = await this.sbTo(this.supabase
			.from('building')
			.upsert(building, { onConflict: 'name,bid_revision_id' }));
		if (err) {
			throw err;
		} else {
			return this._.get(response, '[0]', null);
		}
	}
}
