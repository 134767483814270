import bidLogService from '../../services/bid-log-service.mjs';

export default {
	namespaced: true,
	state: {
		bids: [],
		query: {
			filters: {
				bid_due: {
					value: {
						start: '2022-01-01',
						end: '2022-12-31',
					},
					filterName: 'bid_due',
					key: 'custom2',
					filterType: 'between dates',
					dataType: 'date',
					display: true,
					sortable: true,
				},
			},
			totalRows: 0,
			limit: 9999,
			from: 0,
			to: 9999,
			sort: {
				by: '',
				desc: false,
			},
		},
		availableFilters: [],
		columns: [],
		filterGroups: [
			{
				name: 'General Details',
				show: true,
				render: true,
				fields: [
					{
						name: 'Name',
						key: 'name',
						filterName: 'name',
						dataType: 'string',
						filterType: 'fuzzy match',
						sortable: true,
						display: true,
						stickyColumn: true,
						thClass: 'custom-sticky-header',
						thAttr: {
							'column-class': 'custom-sticky-column-name',
						},
						tdClass: 'custom-sticky-column-name',
						order: 2,
					},
					{
						name: 'Bid No.',
						key: 'custom10',
						filterName: 'bid_number',
						dataType: 'string',
						filterType: 'fuzzy match',
						sortable: true,
						display: true,
						stickyColumn: true,
						thClass: 'custom-sticky-header',
						thAttr: {
							'column-class': 'custom-sticky-column-bid_number',
						},
						tdClass: 'custom-sticky-column-bid_number',
					},
					{
						name: 'Target win',
						key: 'custom9',
						filterName: 'target_win',
						dataType: 'bool',
						filterType: 'YES/NO/EITHER',
						sortable: true,
						display: true,
						thClass: 'text-center custom-sticky-header',
						thAttr: {
							'column-class': 'custom-sticky-column-target_win',
						},
						tdClass: 'custom-sticky-column-target_win',
						order: 1,
					},
					{
						name: 'Week',
						key: 'custom1',
						filterName: 'week',
						dataType: 'number',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'bidLog/getWeeks',
						stickyColumn: true,
						thClass: 'custom-sticky-header',
						thAttr: {
							'column-class': 'custom-sticky-column-week',
						},
						tdClass: 'custom-sticky-column-week',
						order: 3,
					},
					{
						name: 'Status',
						key: 'custom8',
						filterName: 'status',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getBidStatuses',
					},
					{
						name: 'Bid type',
						key: 'bid_type',
						filterName: 'bid_type',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getBidTypes',
						order: 9,
					},
					{
						name: 'Estimator',
						key: 'custom3',
						filterName: 'estimator',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'estimator/getEstimators',
						order: 15,
					},
					{
						name: 'Completed',
						key: 'completed',
						filterName: 'completed',
						dataType: 'bool',
						filterType: 'YES/NO/EITHER',
						sortable: true,
						display: true,
						tdClass: 'text-center',
					},
					{
						name: 'Panel',
						key: 'is_panel_job',
						filterName: 'is_panel_job',
						dataType: 'bool',
						filterType: 'YES/NO/EITHER',
						sortable: true,
						display: true,
						tdClass: 'text-center',
					},
					{
						name: 'Plan type',
						key: 'plan_type',
						filterName: 'plan_type',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getPlanTypes',
					},
					{
						name: 'Project type',
						key: 'project_type',
						filterName: 'project_type',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getProjectTypes',
					},
					{
						name: 'Takeoff type',
						key: 'takeoff_type',
						filterName: 'takeoff_type',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getTakeoffTypes',
					},
					{
						name: 'Award Status',
						key: 'award_status',
						filterName: 'award_status',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getBidAwardStatuses',
						order: 24,
					},
					{
						name: 'Grade',
						key: 'custom11',
						filterName: 'grade',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getBidGrades',
						order: 4,
					},
					{
						name: 'Scope',
						key: 'bid_scope',
						filterName: 'bid_scope',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getBidScopeTypes',
						order: 18,
					},
					{
						name: 'GC',
						key: 'general_contractor',
						filterName: 'general_contractor',
						dataType: 'string',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'generalContractor/getGeneralContractors',
						order: 6,
					},
				],
			},
			{
				name: 'Location',
				show: false,
				render: true,
				fields: [
					{
						name: 'City',
						key: 'city',
						filterName: 'city',
						dataType: 'string',
						filterType: 'fuzzy match',
						sortable: true,
						display: true,
					},
					{
						name: 'State',
						key: 'state',
						filterName: 'state',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'types/getStates',
						order: 5,
					},
					{
						name: 'Region',
						key: 'region',
						filterName: 'region',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'region/getRegions',
						order: 26,
					},
					{
						name: 'Address',
						key: 'address',
						filterName: 'address',
						dataType: 'string',
						filterType: 'fuzzy match',
						sortable: true,
						display: true,
					},
				],
			},
			{
				name: 'Timeline',
				show: false,
				render: true,
				fields: [
					{
						name: 'Bid due',
						key: 'custom2',
						filterName: 'bid_due',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 14,
					},
					{
						name: 'Bid done',
						key: 'bid_done',
						filterName: 'bid_done',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 21,
					},
					{
						name: 'Frame start',
						key: 'frame_start',
						filterName: 'frame_start',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 19,
					},
					{
						name: 'Frame end',
						key: 'frame_end',
						filterName: 'frame_end',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 20,
					},
					{
						name: 'Plan date',
						key: 'plan_date',
						filterName: 'plan_date',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
					},
					{
						name: 'Award Date',
						key: 'award_date',
						filterName: 'award_date',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 25,
					},
					{
						name: 'Est Start',
						key: 'estimated_start',
						filterName: 'estimated_start',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 12,
					},
					{
						name: 'Est Finish',
						key: 'estimated_finish',
						filterName: 'estimated_finish',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 13,
					},
					{
						name: 'ITB Received',
						key: 'itb_received',
						filterName: 'itb_received',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 10,
					},
					{
						name: 'RFQ Received',
						key: 'rfq_received',
						filterName: 'rfq_received',
						dataType: 'date',
						filterType: 'between dates',
						sortable: true,
						display: true,
						order: 11,
					},
				],
			},
			{
				name: 'Notes',
				show: false,
				render: true,
				fields: [
					{
						name: 'Desc',
						key: 'custom6',
						filterName: 'description',
						dataType: 'text',
						filterType: 'fuzzy match',
						sortable: true,
						display: true,
						order: 7,
					},
					{
						name: 'Notes',
						key: 'custom7',
						filterName: 'project_notes',
						dataType: 'text',
						filterType: 'fuzzy match',
						sortable: true,
						display: true,
						order: 8,
					},
				],
			},
			{
				name: 'Additional Details',
				show: false,
				render: true,
				fields: [
					{
						name: 'Non standard',
						key: 'non_standard',
						filterName: 'non_standard',
						dataType: 'bool',
						filterType: 'YES/NO/EITHER',
						sortable: true,
						display: true,
						tdClass: 'text-center',
					},
					{
						name: 'Rev No.',
						key: 'revision_num',
						filterName: 'revision_num',
						dataType: 'number',
						filterType: 'fuzzy match',
						sortable: true,
						display: true,
						tdClass: 'text-right',
					},
					{
						name: 'Buildings',
						key: 'buildings',
						filterName: 'buildings',
						dataType: 'number',
						filterType: 'between numbers',
						sortable: true,
						display: true,
						tdClass: 'text-right',
						order: 27,
					},
					{
						name: 'Est Hours',
						key: 'estimated_hours',
						filterName: 'estimated_hours',
						dataType: 'number',
						filterType: 'between numbers',
						sortable: true,
						display: true,
						tdClass: 'text-right',
						order: 16,
					},
					{
						name: 'Actual Hours',
						key: 'actual_hours',
						filterName: 'actual_hours',
						dataType: 'number',
						filterType: 'between numbers',
						sortable: true,
						display: true,
						tdClass: 'text-right',
						order: 17,
					},
				],
			},
			{
				name: 'Engineer & Architect',
				show: false,
				render: true,
				fields: [
					{
						name: 'SE',
						key: 'structural_engineer',
						filterName: 'structural_engineer',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'engineer/getEngineers',
					},
					{
						name: 'Architect',
						key: 'architect',
						filterName: 'architect',
						dataType: 'set',
						filterType: 'multi (or)',
						sortable: true,
						display: true,
						getter: 'architect/getArchitects',
					},
				],
			},
			{
				name: 'Square Footage',
				show: false,
				render: true,
				fields: [
					{
						name: 'Sqft',
						key: 'square_footage',
						filterName: 'square_footage',
						dataType: 'number',
						filterType: 'between numbers',
						sortable: true,
						display: true,
						tdClass: 'text-right',
						order: 22,
					},
					{
						name: 'Porch Sqft',
						key: 'porch_square_footage',
						filterName: 'porch_square_footage',
						dataType: 'number',
						filterType: 'between numbers',
						sortable: true,
						display: true,
						tdClass: 'text-right',
					},
				],
			},
			{
				name: 'Bid Amount',
				show: false,
				render: true,
				fields: [
					{
						name: 'Labor',
						key: 'labor',
						filterName: 'labor',
						dataType: 'dollar',
						filterType: 'between dollars',
						sortable: true,
						display: true,
						tdClass: 'text-right',
					},
					{
						name: 'Material',
						key: 'material',
						filterName: 'material',
						dataType: 'dollar',
						filterType: 'between dollars',
						sortable: true,
						display: true,
						tdClass: 'text-right',
					},
					{
						name: 'Current bid',
						key: 'current_bid',
						filterName: 'current_bid',
						dataType: 'dollar',
						filterType: 'between dollars',
						sortable: true,
						display: true,
						tdClass: 'text-right',
						order: 23,
					},
				],
			},
			{
				name: 'Others',
				show: false,
				render: false,
				fields: [
					{ 	name: 'Activities',
						key: 'custom4',
						filterName: 'activities',
						sortable: true,
						display: false,
					},
					{
						name: 'Comments',
						key: 'custom5',
						filterName: 'comments',
						sortable: true,
						display: false,
					},
				],
			},
		],
		status: 'loading',
		activities: [],
		comments: [],
		table: 'ARCHIVEDBIDLOG',
	},
	getters: {
		getBidsComments: (state) => (bidId) => state.comments.filter((comment) => comment.resource_id === bidId),
		getBidsActivities: (state) => (bidId) => state.activities.filter((activity) => activity.resource_id === bidId),
		getWeeks: () => {
			const weeks = [];
			for (let index = 1; index <= 52; index++) {
				weeks.push(index);
			}

			return weeks;
		},
		getRenderableFilterGroups: (state) => state.filterGroups.filter(({ render }) => render === true),
	},
	mutations: {
		...bidLogService.mutations,
	},
	actions: {
		...bidLogService.actions,
		async getAllBids({ commit, state }) {
			const { to, bidRepo, logDnaFactory: logger, _ } = this.$cradle;
			const query = _.cloneDeep(state.query);

			const [err, data] = await to(bidRepo.getArchivedBidLog(query));
			if (err) {
				logger.error(err.message);
				commit('setBids', []);
				throw err;
			} else {
				const { data: bids, count } = data;

				commit('setTotalRows', count);
				commit('setBids', bids);

				if (!bids) {
					commit('setStatus', 'display');
					return;
				}

				commit('setStatus', 'display');
			}
		},
		async getComments({ commit, state }, bidId) {
			const existingComments = state.comments.find((comment) => comment.resource_id === bidId);
			if (existingComments) return;
			const { commentRepo, logDnaFactory: logger, to } = this.$cradle;
			const [err, comments] = await to(commentRepo.getCommentsByBidId(bidId));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setComments', comments);
		},
		async getActivities({ commit, state }, bidId) {
			const existingActivities = state.activities.find((comment) => comment.resource_id === bidId);
			if (existingActivities) return;
			const { activityRepo, logDnaFactory: logger, to } = this.$cradle;
			const [err, activities] = await to(activityRepo.getActivitiesByBidId(bidId));
			if (err) {
				logger.error(err.message);
				throw err;
			}

			commit('setActivities', activities);
		},
		async download({ state, rootState }, name) {
			const { _, to, bidRepo, logDnaFactory: logger } = this.$cradle;
			const sendTo = rootState.authentication.user.email;
			const query = _.cloneDeep(state.query);

			const columns = state.columns.filter(({ display }) => display).map(({ filterName }) => filterName);
			const [err] = await to(bidRepo.downloadArchivedBidLog(sendTo, query, columns, name));

			if (err) {
				logger.error(err.message);
				throw err;
			}
		},
	},
};
