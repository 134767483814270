export default class LevelRepo {
	constructor({ to, supaTo, supabaseClientFactory, _ }) {
		this.to = to;
		this.supaTo = supaTo;
		this.supabase = supabaseClientFactory;
		this._ = _;
	}

	async createLevel(buildingId, type) {
		if (!buildingId) {
			throw new Error('Building ID is required to create a new level');
		}

		const [err, response] = await this.supaTo(
			this.supabase.rpc('create_level_fn', { building_id: buildingId, type }),
		);

		if (err) {
			throw err;
		}

		return this.getLevelById(response?.data?.[0]?.id);
	}

	async getLevelById(levelId) {
		const [error, level] = await this.supaTo(
			this.supabase
				.from('level')
				.select('*')
				.match({ id: levelId }),
		);

		if (error) {
			throw error;
		} else {
			return level.data[0];
		}
	}

	async updateLevel(level) {
		if (!level) {
			throw new Error('level updates are required to update a level');
		}
		const [error, data] = await this.supaTo(
			this.supabase
				.from('level')
				.update(level)
				.match({ id: level.id }),
		);
		if (error) {
			throw error;
		} else if (this._.has(data, 'data.length')) {
			return this.getLevelById(level.id);
		} else {
			throw new Error('Unable to update level.');
		}
	}
}
