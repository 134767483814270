## [1.5.0-beta] - 9/20/2022
- Adjust the Bid Log to display in Smartsheet Order
- Bug: fix overflow scroll on Bid Log
- Bug: Error on Revision number Filter
- Bug: Error on Material Filter
- Split the Archived Bid Log by year
- Bug: Fix the Estimating Summary View
- Bug: Fix error on Pricing Whiteboard View
- Bug: Show all Records on Purchase Takeoffs
- Bug: Fix double scroll bars on various screens
- Bug: Bid Notes not editable
- Major improvements to the Bid Edit screen

## [1.4.0-beta] - 9/13/2022
- Bug: Ensure that Plan Type filters as expected
- Bug: Add GC/Owner to the list
- Bug: Remove the Filter param if empty 
- Allow log tables to have Smartsheet like infinite scroll  
- Move breadcrumbs to the top nav
- Switch nav toggle to hamburger icon always  

## [1.3.0-beta] - 9/06/2022
- Fixes to map pins on Bids and Projects
  - Pin color matches potential states of partial addresses
  - Include a legend for the pin status
  - Denote when a pin is manually placed by a human vs machine coded
- Adding inbound email archive for Projects
  - Project specific email address for archiving email via TO: BCC: CC: or forwarding
  - Cryptographic signature email for proof of receipt
  - View the archived emails per Project
- Adjust edge functions to use a cleaner interface and deploy process
- Include doppler in the process vs config file
- Add additional columns to the Project list with improved formatting
- Refinements to the Suppliers sections
  - Ability to upload files via drag and drop
  - Column to denote "In Quote"
  - $ Amount field to record quote amount
  - Record an Activity for supplier actions
- Adding Pricing Whiteboard screen to track pricing by region and effective date
- Improved filtering UI for Projects and Bids (Auto-trader style UI)
  - Field grouping by context
  - Filter by date ranges, start and end dates
  - Filter by fuzzy match
  - Filter by multi (or)
  - Filter by between numbers
  - Filter by between dollars
  - Filter by between percent
  - Filter by Yes/No/Either
  - Save and share filters
- Bug: @mention in comment fix
- Bug: Create a new bid fix
- Bug: Fix for Dashboard not loading
- Bug: Fix for Bid Log not loading
- Bug: Fix typo on the Projects table
- Bug: Fixing issues with schedule, region_id, schedule-frame, budget
- Bug: Fixing issue with budget widget
- Improve the default state when the Suppliers list is empty
- Adjust download feature for (Projects,Bids)
- Show Bid Revisions and allow navigation between them
- All CS Sheets now import ~(4000)
- Import building info from the Sub vs MH tab from the CS Sheet
- Adjustments to the Levels tab to map to the Sub vs MH tab
- Improvements to the Bid import (Project Notes, Regions, Bid Type, Bid Done)
- Move Bid Import pipeline to Prefect/Docker to improve speed and reliability
- Projects view new columns
  - budget_remaining
  - budget_percent_utilized
  - schedule_percent_utilized
  - schedule_elapsed_days
  - schedule_remaining_days
  - original_margin
  - revised_margin
  - current_margin
- Adjustments to the Logs to make them more data dense and improved formatting

## [1.2.0-beta] - 5/31/2022
- Fixes to remove old columns in the database
- Ability to import more varieties of CS Sheets
- Improvements to the Projects UI to handle new data imported from Sage
- Ability to match CS Sheets against Sage data (Will need manual intervention to reach 100%)
- Add total to the footer of tables
- Improvements to the projects list page

## [1.1.0-beta] - 5/16/2022
- Ability to export Bids and Projects to a spreadsheet
- Table settings are now savable
- Bug: Browser no longer caches
- Add page details to LogDNA
- Display the prediction version and date
- Regional Report Improvements
  - Filter by more than one region
  - Formatting is consistent with other UI
  - Bug: loading bug with an error about destructuring something undefined
  - Ensure that the regions selected are visible at the top
  - Name should truncate/overflow ellispes
  - Show regions filtered to above the fold 
- Improve BreadCrumbs
- Right Nav can be used on Projects and Bids
- Bug: Comment views are not dropped
- Dashboard/List Improvements
  - Clicking on items on the dashboard should take you to a filtered bid list by those items
  - Ability to reorder the columns on a list view and save to a filter on the Bid Log 
  - Bug: Filter dropdown on the Bid Log doesn't switch when the filter is cleared or selected from the Filter Tab
  - Bug: Dashboard Headshot boxes - when hovering a bid link, don't fully expand the name, keep it ellipse'd
  - Adding counts to the number of filters selected
- Bug: Error on trying to save level info
- Bug: Building/Level Metrics not Updating on Navigation Change
- Bug: Clone a Bid doesn't create a new Bid No.
- Show the Release Notes in the UI
- Add lat/lon properties to import pipeline
- Only generate lat/lon if the Job is being imported
- Swap date pickers to fix a memory leak
- Bug: fixing building order to always work
- Bug: regions dropdown not populating
- Bug: fix multiselect dropdowns moving the form content

## [1.0.0-beta] - 3/28/2022
- Tests for Comments, Activities and Data Sources
- Diffing mechanism for adding data from Sage
- Improvements to the Comments interface
- Various UI fixes
  - Projects Notes no longer wrap
  - Regional Report UI improvements
  - Bid Log filtering, alignment and UI improvements
- Data pipeline via Prefect first pass
- New Project Page
- Bug: Linter not committing fixes
- Allow Comments, Activities and Data Sources to attach to more entities 
- Bug: Figure out pg backups w/ auth schema
- Bug: Understand why the CDN cache's on deploy
- Setup Prefect for data import from Sage
- UI Clean up from Estimating Team
  - Breadcrumbs not behaving correctly
  - Checkboxes not centered
  - Sub header padding
  - Modal headers have no bottom border
  - Actions should always be right justified
  - Adjustments to the Suppliers Page
  - Comments not loading
  - Date choosers icons aren't clickable
  - Short Plan Type by ABC order
- Seeds for Bids and Archived Bids
- Adjust ML node api for forward compatibility
- Search now runs on the DB
- Comments email now uses the email template
